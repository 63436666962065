import { createContext, useContext, useState, useEffect } from 'react';
import instance from '../Instance/instance';

const AclContext = createContext();

export const AclProvider = ({ children }) => {

    const [pagesData, setPagesData] = useState([]);

    const permissionId = localStorage?.getItem("permissionId");

    const getPagesPermission = async () => {
        if (permissionId) {
            try {
                const res = await instance.get(`api/Page/GetPagePermission?roleId=${permissionId}`)
                setPagesData(res?.data?.data);
            } catch (err) {
                console.log(err)
            } finally {

            }
        }
    }
    useEffect(() => {
        if (permissionId) {
            getPagesPermission();
        }
    }, [permissionId]);
    return (
        <AclContext.Provider value={{ pagesData, setPagesData }}>
            {children}
        </AclContext.Provider>
    );
};

export const useAcl = () => {
    return useContext(AclContext);
};