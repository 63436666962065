import { useAcl } from '../context/aclContex';
import { useUser } from '../context/userContext';
import { getRoles } from '../roleAndPermissions/getRoles';

const useUserRole = () => {
    const userName = localStorage.getItem("userType")?.toLowerCase() || "";  // Ensure it's a string
    const pathSegments = window.location.pathname.split('/').filter(Boolean);
    const pathName = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1].split('-').join(" ") : "";
console.log("userRole",pathName)
    const { userType } = useUser();
    const { pagesData } = useAcl();

    if (userType && userType.toLowerCase() === userName) {  
        return getRoles(userName, pagesData, pathName);
    }

    return null; 
};

export default useUserRole;












// old code 
// import { useAcl } from '../context/aclContex';
// import { useUser } from '../context/userContext';
// import { getRoles } from '../roleAndPermissions/getRoles';

// const useUserRole = () => {
// const userName = localStorage.getItem("name")
//     // const pathSegments = window.location.pathname.split('/').filter(Boolean);
//     // const pathName =pathSegments[pathSegments.length - 1].split('-').join(" ")
//     const pathSegments = window.location.pathname.split('/').filter(Boolean);
//     const pathName = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1].split('-').join(" ") : "";
// // console.log(pathName,"---=userName-----")
//     const { userType } = useUser();
//     const { pagesData } = useAcl()
//     if (userType === userName.toLowerCase()) return getRoles(userName.toLowerCase(), pagesData, pathName);
//     // if (userType === 'superadmin') return getRoles('superadmin', pagesData, pathName);
//     // if (userType === 'user') return getRoles('user', pagesData, pathName);
// };

// export default useUserRole;

