import React, { lazy } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Suspense } from "react";
import Login from '../pages/Login';
import AdminPrivate from './adminPrivateRoute';
import Loader from '../components/Loader';

import useUserRole from '../hooks/useUserRole';
import { superAdminList } from './RouteList';
import { useAcl } from '../context/aclContex';

const MyRoutes = () => {
    const token = window?.localStorage?.getItem("token") ? true : false

    const Dashboard = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/Dashboard")), 1300);
        });
    });

    const Attendance = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/Attendance")), 1300);
        });
    });
    const Leaves = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/Leaves")), 1300);
        });
    });
    const LeaveDetails = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/LeaveDetails")), 1300);
        });
    });
    const Overtime = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/Overtime")), 1300);
        });
    });

    const AttendanceDetail = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/AttendanceDetail")), 1300);
        });
    });

    const DeletedEmployers = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/DeletedEmployers")), 1300);
        });
    });

    const ActiveEmployees = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/ActiveEmployees")), 1300);
        });
    });

    const RequestCheckout = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/RequestCheckout")), 1300);
        });
    });
    const PublicHolidays = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/PublicHolidays")), 1300);
        });
    });
    const SubAdmin = lazy(() => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(import("../pages/SubAdmin")), 1300);
        });
    });
const { pagesData } = useAcl()
    const roles = useUserRole()

    // const filteredRoutes = superAdminList.filter(item => !roles?.hide?.includes(item?.type));

    const filteredRoutes = superAdminList
    .map(item => {
        const isParentMatched = pagesData.some(c =>
            c.page === item.name && (c?.isCreate || c?.isDelete || c?.isUpdate || c?.isRead )
        );
        const filteredSubMenu = item.subMenu?.filter(sub =>
            pagesData.some(c =>
                c.nestedPages?.some(nested =>
                    nested.page === sub.name && (nested?.isCreate || nested?.isDelete || nested?.isUpdate || nested?.isRead)
                )
            )
        );
        if (isParentMatched || (filteredSubMenu && filteredSubMenu.length > 0)) {
            return { ...item, subMenu: filteredSubMenu };
        }
        return null;
    })
    .filter(Boolean);

    console.log(filteredRoutes,"filteredRoutes-----------myRoutes123")
    console.log(pagesData,"pagesData-----------pagesData-------------")
    // acl updated code start 
    // const filteredRoutes = superAdminList.filter(item => 
    //     pagesData.some(c => c.page === item.name && (c?.isCreate || c?.isDelete || c?.isUpdate ||c?.isRead))
    // );
    // acl updated code end

    // acl start 
    // const pages = JSON.parse(localStorage.getItem('pages'))
    
    
    // const updatedList = superAdminList.map((x) => {
        //     return pages?.map(y => ({ ...x, permission: { isCreated: y?.isCreate, isRead: y.isRead, isUpdate: y.isUpdate, isDelete: y?.isDelete } }))
        // })
        // console.log(updatedList, "updatedListupdatedList")
        // acl end
        




    // const filterRoutesByPermissions = (routes, permissions) => {
    //     if (permissions.length > 0) {

    //         return routes.filter((route) => {
    //             const pagePermission = permissions.find((p) => p.page === route.permission);
    //             if (!pagePermission) return false;

    //             // Check if all required actions are allowed
    //             const { requiredActions } = route;
    //             return Object.keys(requiredActions).every(
    //                 (action) => requiredActions[action] === true && pagePermission[`is${action.charAt(0).toUpperCase() + action.slice(1)}`] === 1
    //             );
    //         });
    //     }
    // };



    // console.log(filterRoutesByPermissions(updatedList, pages), "-=-=-=-=-=-permission--->><<<", pages)


    const transformPages = (apiData, staticRoutes) => {
        let transformedList = [];
    
        apiData.forEach((page) => {
            if (page.nestedPages && page.nestedPages.length > 0) {
                let validNestedPages = page.nestedPages.filter(
                    (nested) => nested.isRead || nested.isCreate || nested.isUpdate || nested.isDelete
                );
    
                if (validNestedPages.length > 0) {
                    transformedList.push(...validNestedPages);
                    transformedList.push({
                        ...page,
                        nestedPages: null // Make sure nestedPages are removed from the parent
                    });
                    return;
                }
            }
            if (page.isRead || page.isCreate || page.isUpdate || page.isDelete) {
                transformedList.push(page);
            }
        });
        return staticRoutes.filter(route =>
            transformedList.some(page => page.page === route.name && 
                (page.isRead || page.isCreate || page.isUpdate || page.isDelete)
            )
        );
    };
    
    
    const finalRoutes = transformPages(pagesData, superAdminList);
    
    console.log(finalRoutes, "finalRoutes");
    
    
    


    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Routes>
                    {token ? <Route path="/dashboard" element={<Dashboard />}></Route> : <Route path="/login" element={<Login />}></Route>}
                    <Route element={<AdminPrivate token={token} />}>
                        {finalRoutes?.map((item) => (
                            <Route key={item.path} path={`/${item.path}`} element={item.element} />
                        ))}

                        {/* <Route path="/dashboard" element={<Dashboard />}></Route>
                        <Route path="/attendance" element={<Attendance />}></Route>
                        <Route path="/attendanceDetail" element={<AttendanceDetail />}></Route>
                        <Route path="/deletedEmployees" element={<DeletedEmployers />}></Route>
                        <Route path="/activeEmployees" element={<ActiveEmployees />}></Route>
                        <Route path="/requestCheckout" element={<RequestCheckout />}></Route>
                        <Route path="/over-time" element={<Overtime />}></Route>
                        <Route path="/leaves" element={<Leaves />}></Route>
                        <Route path="/leavedetails" element={<LeaveDetails />}></Route>
                        <Route path="/publicholidays" element={<PublicHolidays />}></Route>
                        <Route path="/sub-admin" element={<SubAdmin />}></Route> */}
                    </Route>
                    <Route path="*" element={token ? (<Navigate to="/dashboard" replace />) : (<Navigate to="/login" replace />)}></Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default MyRoutes