import { useAcl } from "../context/aclContex";

export const getRoles = (userType, pagesData, pathName) => {
    // const pages = localStorage.getItem('pages')
    // console.log(pagesData,"pagesData getRoles<><><><><______-----------5555555555",pathName)
// console.log(pathName,"-----------------------")
    const filter = pagesData.filter(x => x.page.toLowerCase() == pathName.toLowerCase())
    const filter1 = pagesData.flatMap(x => {
        console.log(x.page.toLowerCase().replace(/\s+/g, ''),"=---------------page name------------------------------")
        console.log(pathName.toLowerCase(),"=------------------pathName---------------------------")
        if (x.nestedPages.length == 0 && x.page.toLowerCase().replace(/\s+/g, '') == pathName.toLowerCase()) {
            if (x.isCreate || x.isRead || x.isUpdate || x.isDelete) {
                return [x]; 
            }
        } else if (Array.isArray(x.nestedPages) && x.nestedPages.length > 0) {
            const matchingNestedPages = x.nestedPages.filter(nestedPage => {
                // console.log(nestedPage.page.toLowerCase().replace(/\s+/g, ''), "nestedPage.page.toLowerCase().replace(/\s+/g, '')");
                // console.log(pathName.toLowerCase().replace(/\s+/g, ''), "pathName.toLowerCase().replace(/\s+/g, '')");
    
                return nestedPage.page.toLowerCase().replace(/\s+/g, '') === pathName.toLowerCase().replace(/\s+/g, '') &&
                    (nestedPage.isCreate || nestedPage.isRead || nestedPage.isUpdate || nestedPage.isDelete);
            });
    
            if (matchingNestedPages?.length > 0) {
                return matchingNestedPages;
            }
        }
    
        return [];
    });
    
    console.log(filter1,"filter1filter1filter1");
    
    

    // console.log(filter1, "filterfilter.....................................................")
    // console.log(pathName, "pathName.....................................................")
    // console.log(pagesData, "pagesData.....................................................")
    const roles = {
        create: false,
        edit: false,
        redeem: false,
        // ...filter[0],
        ...filter1[0],
        hide: []
    };

    // if (userType === 'superadmin') {
    //     roles.create = true;
    //     roles.redeem = false;
    //     roles.edit = true;
    //     roles.hide = ['']

    // } else if (userType === 'admin') {
    //     roles.create = true;
    //     roles.redeem = false;
    //     roles.edit = false;
    //     roles.hide = ['admin']

    // } else if (userType === 'user') {
    //     roles.create = false;
    //     roles.redeem = true;
    //     roles.edit = false;
    //     roles.hide = ['']

    // } 
    return roles;
};