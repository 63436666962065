import React, { lazy } from 'react'
const Dashboard = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Dashboard")), 1300);
    });
});

const Attendance = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Attendance")), 1300);
    });
});
const Leaves = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Leaves")), 1300);
    });
});
const LeaveDetails = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/LeaveDetails")), 1300);
    });
});
const Overtime = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Overtime")), 1300);
    });
});

const AttendanceDetail = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/AttendanceDetail")), 1300);
    });
});

const DeletedEmployers = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/DeletedEmployers")), 1300);
    });
});

const ActiveEmployees = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/ActiveEmployees")), 1300);
    });
});

const RequestCheckout = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/RequestCheckout")), 1300);
    });
});
const PublicHolidays = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/PublicHolidays")), 1300);
    });
});
const SubAdmin = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/SubAdmin")), 1300);
    });
});
const ProjectManagement = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/ProjectManagement")), 1300);
    });
});
const AssignRole = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/AssignRole")), 1300);
    });
});
const TaskManagement = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/TaskManagement")), 1300);
    });
});
const TaskList = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/TaskList")), 1300);
    });
});



export const superAdminList = [
    {
        path: "/dashboard",
        type: "superadmin",
        name:"Dashboard",
        element: <Dashboard />,
    },
    {
        path: "/attendance",
        type: "superadmin",
        name:"Attendance",
        // permission:"Attendance",
        element: <Attendance />,
    },
    {
        path: "/attendanceDetail",
        type: "superadmin",
        name:"Attendance Detail",
        element: <AttendanceDetail />,
    },
    {
        path: "/inactiveEmployees",
        type: "superadmin",
        name: "Inactive Employees",
        element: <DeletedEmployers />,
    },
    {
        path: "/activeEmployees",
        name: "Employees",
        type: "superadmin",
        element: <ActiveEmployees />,
    },
    {
        path: "/requestCheckout",
        type: "superadmin",
        name:"Request Checkout",
        element: <RequestCheckout />,
    },
    {
        path: "/overtime",
        type: "superadmin",
        name:"Overtime",
        element: <Overtime />,
    },
    {
        path: "/leaves",
        type: "superadmin",
        name:"Leaves",
        element: <Leaves />,
    },
    {
        path: "/leavedetails",
        name:"Leave Details",
        type: "superadmin",
        element: <LeaveDetails />,
    },
    {
        path: "/publicholidays",
        type: "superadmin",
        name: "Public Holidays",
        element: <PublicHolidays />,
    },
    {
        path: "/assignrole",
        type: "superadmin",
        name: "Assign Role",
        element: <AssignRole />,
    },
    {
        path: "/subadmin",
        type: "admin",
        name: "SubAdmin",
        element: <SubAdmin />,
    },
    {
        path: "/projects",
        type: "admin",
        name: "Projects",
        element: <ProjectManagement />,
    },
    {
        path: "/taskmanagement",
        type: "admin",
        name: "Task Management",
        element: <TaskManagement />,
    },
    {
        path: "/tasklist",
        type: "admin",
        name: "Task List",
        element: <TaskList />,
    },

]